body {
  height: 100%;
  background-color: #18181a;
  color: white;
  font-family: 'Cascadia Mono Light', 'Courier New';
  padding-bottom: 50px;
}

h1 {
    font-family: monospace;
}

.navbar {
  text-align:center;
  z-index: 10;
}

.navbar * {
  padding: 5px;
}

.nav-link {
  padding: 0;
}

a {
  color: rgb(197, 201, 216);
}

a:hover {
  color: rgb(117, 117, 173);
}

.tsparticles-canvas-el {
  position: absolute;
  left: 0;
  z-index: -1;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
